import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO site={data.site} title="Krzysztof Witczak's Talks" />
        <h2 className="about-header">My talks</h2>
        
        <div className="talks-content">
          <p>
            Below you can download slides from my previous talks.
          </p>
          <ul>
            <li className="talks-li">
              <span className="date-field">February 12, 2020</span>
              <ul>
                <li>
                  <a target="_blank" href="https://www.facebook.com/SolDevelo">SolCon #1</a>
                </li>
                <li>
                  <a target="_blank" href="https://drive.google.com/file/d/1C3mBZphKhxMa3fYB_aBO6l_-LS7evFy7/view?usp=sharing">AWS - 10.000-foot Overview [ENG]</a>
                </li>
              </ul>
            </li>
            <li className="talks-li">
              <span className="date-field">March 15, 2017</span>
              <ul>
                <li>
                  <a target="_blank" href="https://www.facebook.com/events/500507833671556">CodeSkill #7</a>
                </li>
                <li>
                  <a target="_blank" href="https://drive.google.com/file/d/0B7VgLkEx42J6QXpWTW9VYVFWZGs/view?usp=sharing">Go SCRUM yourself [PL]</a>
                </li>
              </ul>
            </li>
            <li className="talks-li">
              <span className="date-field">November 23, 2016</span>
              <ul>
                <li>
                  <a target="_blank" href="https://www.facebook.com/events/700640630094012/">CodeSkill #2</a>
                </li>
                <li>
                  <a target="_blank" href="https://drive.google.com/file/d/0B7VgLkEx42J6SlRHU180WnFLS0U/view?usp=sharing">Ruby - A komu to potrzebne? A dlaczego? [PL]</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
  }
`;
